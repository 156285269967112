@keyframes show-btn {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.btn-scroll{
  position: fixed;
  bottom: rem(20);
  left: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  animation: show-btn 1s ease infinite;
  transition: $transition-base;
  transform: translateX(-50%);
  z-index: 10;

  i{
    font-size: rem(75);
  }

  @include media('>=md') {
    // width: rem(50);
    // height: rem(50);
    bottom: rem(30);
  }

  @include media('<sm') {
    display: none;
  }
}
