.intro {
  &__heading {
    text-align: center;
    font-size: rem(20);
    max-width: rem(400);
    margin: 0 auto rem(40);

    @include media('>=md'){
      margin: 0 auto 8.5rem;
      font-size: rem(25);
      max-width: rem(500);
    }
  }

  &__box {
    font-size: rem(20);
    text-transform: uppercase;
    font-weight: bold;
    border: 6px solid #fff;
    padding: rem(10) rem(20);
    margin-bottom: rem(15);

    @include media('>=sm') {
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
    }

    @include media('>=md') {
      font-size: rem(26);
    }

    &--bigger {
      font-size: rem(27);
      line-height: 1.3;

      @include media('>=sm') {
        font-size: rem(24);
      }

      @include media('>=md') {
        font-size: rem(36);
      }
    }
  }
}
