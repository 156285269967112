.cookies-banner{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  font-size: rem(16);
  z-index: 99;
  padding: rem(20) rem(0);

  .container{
    @include media('>=md'){
      display: flex;
      align-items: center;
    }
  }

  &__content{
    a{
      text-decoration: underline;
    }

    @include media('>=md'){
      flex: 1;
    }
  }

  &__btns{
    text-align: right;
  }
}
