// Colors

$gray:                      #595959;            // Gray
$gray-dark:                 #292929;
$gray-light:                #a6a6a6;

$color1:                    #1b286b;            // Blue
$color-bf:                  #2999e2;
$color-mz:                  #52d6d8;

// Body

$body-bg:                   $color1;
$body-color:                #fff;

// Base

$font-size-base:            rem(16);            // body tag
$font-family-base:          'Roboto Condensed', sans-serif;;
$line-height-base:          1.5;

$spacer:                    rem(20);

$transition-base:           all .3s ease;

// Wrapper

$wrapper-width-sm:          rem(720);
$wrapper-width-md:          rem(980);

// MQ

$mq-xs:                     em(480);
$mq-sm:                     em(768);
$mq-md:                     em(1024);

$breakpoints: (
  'xs': $mq-xs,
  'sm': $mq-sm,
  'md': $mq-md
);

// Flexbox grid

$flexboxgrid-breakpoints:
  sm $mq-sm $wrapper-width-sm,
  md $mq-md $wrapper-width-md;

$flexboxgrid-gutter-width: rem(40);
