.section {
  padding-top: rem(40);

  @include media('>=sm') {
    padding-top: rem(50);
  }

  &--intro {
    padding-top: 0;
  }

  &--last {
    padding-bottom: rem(40);

    @include media('>=sm') {
      padding-bottom: rem(100);
    }
  }

  &__title {
    font-size: rem(35);
    text-align: center;
    font-weight: normal;
    margin-bottom: rem(30);

    @include media('>=sm') {
      font-size: rem(52);
      text-align: left;
      margin-bottom: rem(40);
    }
  }

  &__intro {
    text-align: center;
    margin-bottom: rem(30);

    @include media('>=md') {
      font-size: rem(20);
      margin-bottom: rem(40);
    }
  }

  &#error{
    position: relative;
    z-index: 5;
    padding-top: rem(40);
    padding-bottom: rem(40);
  }
}
