.mentions-legales{

  .container{
    position: relative;
    z-index: 5;
    margin-bottom: 90px;
  }

  &__title{

    @include media('>=sm') {
      font-size: rem(36);
    }
  }

  &__text{

    @include media('>=sm') {
      font-size: rem(20);
    }
  }

  &__link{
    color: #53BDE5;
    text-decoration: underline;
  }
}
