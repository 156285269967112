.cookies{

  &__title{
    font-size: rem(25);
    font-weight: normal;
    margin-bottom: rem(10);

    @include media('>=sm') {
      font-size: rem(42);
      margin-bottom: rem(20);
    }
  }

  &__subtitle{
    font-size: rem(20);
    margin-bottom: rem(10);

    @include media('>=sm') {
      font-size: rem(25);
      margin-bottom: rem(15);
    }
  }

  &__content{
    @include media('>=md'){
      font-size: rem(18)
    }
  }

  &__item{
    font-size: rem(18)
  }

  &__footer{
    padding: rem(20) 0 rem(30);

    @include media('>=md'){
      text-align: center;
      padding: rem(30) 0 rem(40);
    }
  }
}
