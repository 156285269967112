.header {
  height: rem(75);
  padding: 0 rem(15);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 400;
  transition: $transition-base;

  &--static{
    position: fixed;
    background: #1B286B;
    height: rem(67) !important;

    .header__logo img{
      height: rem(38) !important;
      transition: $transition-base;
    }
  }

  @include media('>=sm') {
    height: rem(100);
    padding: 0 rem(40);
  }

  @include media('>=md'){
    height: rem(115);
  }

  &__logo {
    img {
      height: rem(43);
      display: block;

      @include media('>=sm') {
        height: rem(55);
      }

      @include media('>=md') {
        height: rem(70);
      }
    }
  }

  &__toggle {
    display: block;
    width: rem(40);
    height: rem(40);
    $btn-size: rem(30);
    position: relative;
    z-index: 20;

    @include media('>=sm') {
      display: none;
    }

    .show-nav & {
      span {
        transition: background .3s 0s ease;
        background: transparent;

        &::before, &::after {
          top: 0;
          padding-left: $btn-size;
          transition: top .3s ease, background-color .3s ease, -webkit-transform .3s .3s ease;
          transition: top .3s ease, background-color .3s ease, transform .3s .3s ease;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    span {
      left: rem(5);
      top: 50%;
      margin-top: -2px;
      width: $btn-size;
      height: 4px;
      position: absolute;
      display: inline-block;
      background-color: #fff;
      transition: background .3s .3s ease;

      &::before, &::after {
        content: "";
        z-index: 3;
        left: 0;
        position: absolute;
        display: block;
        overflow: hidden;
        padding: 4px 0 0 $btn-size;
        background-color: #fff;
        transform-origin: 50% 50%;
        transition: top .3s .3s ease, background-color .3s .3s ease, -webkit-transform .3s ease;
        transition: top .3s .3s ease, background-color .3s .3s ease, transform .3s ease;
      }

      &::before {
        top: 10px;
      }

      &::after {
        top: -10px;
      }
    }
  }
}
