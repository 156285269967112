.footer{
  background-color: #fff;
  padding: rem(30) 0 rem(40);
  font-size: rem(15);
  color: #3b3f5d;
  position: relative;
  z-index: 10;

  &.fixed{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @include media('>=sm') {
    padding: rem(50) 0 rem(60);
  }

  &__content{

    @include media('>=sm') {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__logo{
    width: rem(225);
  }

  &__desc{
    margin: rem(30) 0;

     @include media('>=sm') {
      margin-bottom: 0;
     }
  }

  &__contact{
    @extend %list-reset;

    i{
      margin-right: rem(10);
      font-size: rem(20)
    }
  }

  &__item{

    & + &{
      margin-top: rem(10);
    }
  }

  &__btm{
    margin-top: rem(30);
    text-align: center;

    @include media('>=sm') {
      margin-top: rem(50);
    }

    a{
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }

    span{
      display: block;

      @include media('>=sm') {
        display: inline;

        &::before{
          content: " | "
        }
      }
    }
  }
}
