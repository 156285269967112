.form {
  &__group {
    margin-bottom: rem(20);
    position: relative;

    &.required{
      display: none;
    }

    @include media('>=md') {
      margin-bottom: rem(30);
    }

    &--textarea{
      position: relative;
    }

    &.active{

      .form__label{
        font-size: rem(10);
        top: -11px;

        &--textarea{
          font-size: rem(16);
          top: 9px;
          left: -1px;
        }
      }
    }

    &.has-error{

      .form__label, .form__control{
        color: red;
        border-color: red;
      }
    }
  }

  &__error{
    position: absolute;
    right: 0;
    color: red;
    font-size: rem(12);

    @include media('>=md') {
      font-size: rem(18);
    }
  }

  &__label{
    text-transform: uppercase;
    font-size: rem(15);
    position: absolute;
    top: 3px;
    left: 0;
    transition: $transition-base;
    cursor: text;

    &--textarea{
      top: 46px;
      left: 10px;
      color: #a7a7a7;
    }

    @include media('>=md') {
      font-size: rem(22);
    }
  }

  &__control {
    display: block;
    width: 100%;
    border: 0;
    color: #fff;
    font-size: rem(15);
    padding: rem(5) 0;
    text-transform: uppercase;
    background-color: transparent;
    border-bottom: 1px solid #fff;

    &:focus:invalid{
      border-color: red;
    }

    @include placeholder {
      color: #fff;
    }

    @include media('>=md') {
      font-size: rem(22);
    }
  }

  &__textarea {
    background-color: #fff;
    width: 100%;
    border: 0;
    padding: rem(10);
    font-size: rem(15);
    color: $color1;
    height: rem(130);
    text-transform: uppercase;

    @include placeholder {
      color: #a7a7a7;
    }

    @include media('>=sm') {
      height: rem(195);
      margin-top: rem(29);
    }

    @include media('>=md') {
      font-size: rem(22);
      margin-top: rem(36);
      height: rem(272);
    }
  }

  &__submit {
    text-align: center;
    margin-top: rem(30);

    @include media('>=md') {
      margin-top: rem(60);
    }
  }
}
