.banner-msg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  z-index: 400;
  text-align: center;
  display: flex;
  align-items: flex-end;

  @include media('>=sm') {
    align-items: center;
  }

  &__content{
    background-color: $color-mz;
    padding: rem(15);
    width: 100%;

    .warning{
      background: #FF5D00;
    }

    @include media('>=sm') {
      font-size: rem(20);
    }

    @include media('>=md') {
      font-size: rem(25);
    }
  }

  .btn{
    margin: rem(0) auto rem(5);
  }
}
