.nav {
  background-color: $color1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: rem(50) rem(20) rem(40);
  transition: $transition-base;
  opacity: 0;
  visibility: hidden;
  transform: translateY(rem(-20));
  box-shadow: 0 30px 30px rgba(#000, 0.2);

  @include media('>=sm') {
    opacity: 1;
    visibility: visible;
    position: static;
    transform: none;
    transition: none;
    padding: 0;
    width: auto;
    box-shadow: none;
    background: transparent;
  }

  .show-nav & {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  &__menu {
    @extend %list-reset;
    font-size: rem(22);
    text-transform: uppercase;
    text-align: center;

    @include media('>=md'){
      font-size: rem(25);
    }

    @include media('>=sm') {
      display: flex;
    }
  }

  &__item {
    border-bottom: 1px solid #fff;

    @include media('>=sm') {
      border: 0;
      padding: rem(3) 0;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        transform: scaleX(0);
        transition: $transition-base;
      }

      &:hover::after {
        transform: none;
      }
    }

    & + & {
      @include media('>=sm') {
        margin-left: rem(15);
      }

      @include media('>=md') {
        margin-left: rem(30);
      }
    }
  }

  &__link {
    display: block;
    padding: rem(20);

    @include media('>=sm') {
      font-size: rem(16);
      padding: 0;
    }
  }
}
