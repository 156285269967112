// Reset

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  min-width: rem(320);
  color: $body-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-family: $font-family-base;
  background: $body-bg;
  -webkit-font-smoothing: antialiased;
}

// Typography

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 $spacer;
}

p {
  margin: 0 0 $spacer;
}

ol,
ul,
dl {
  margin: 0 0 $spacer;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 $spacer;
}

// Images

img {
  vertical-align: middle;
  max-width: 100%;
}
