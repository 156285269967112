.background {
  background: url(../img/bg-intro.jpg) center top no-repeat;
  background-size: 100% auto;
  position: relative;

  &:first-child {
    padding-top: rem(90);

    @include media('>=sm') {
      padding-top: rem(120);
      background: none;
    }

    @include media('>=md'){
      padding-top: rem(180);
    }
  }

  &--mentions-legales:first-child{

    @include media('>=sm') {
      background: url(../img/bg-mentions-legales.jpg) center top no-repeat;
      background-size: 100% auto;
    }
  }

  &--error:first-child{
    background: none;
    padding-top: rem(90);

    @include media('>=sm') {
      padding-top: rem(120);
      background: none;
    }

    @include media('>=md'){
      padding-top: rem(150);
    }
  }

  &__video {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    display: none;

    @include media('>=sm') {
      display: block;
    }
  }

  &--numbers {
    background-image: url(../img/bg-numbers.jpg);

    @include media('>=sm') {
      background-image: url(../img/bg-numbers-md.jpg);
    }
  }
}
