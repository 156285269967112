// Forms

textarea,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
  border-radius: 0;
  outline: 0;
}

textarea {
  height: auto;
  resize: none;
}

button {
  outline: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
}
