// Headings

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.2;
  font-weight: bold;
}

// Emphasis

small,
.small {
  font-weight: normal;
  font-size: 80%;
}