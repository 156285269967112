.custom-checkbox{
  position: relative;

  label{
    padding-left: rem(34);
    line-height: rem(24);
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;

    &:focus{
      outline: none;
    }
    & + label:after {
      content: "";
      position: absolute;
      top: rem(2);
      left: 0;
      display: block;
      width: rem(25);
      height: rem(25);
      margin-top: -2px;
      margin-right: 5px;
      border: 1px solid #fff;
      border-radius: rem(2);
      transition: 240ms;
      cursor: pointer;
    }
    &:checked + label:before {
      cursor: pointer;
      content: "";
      position: absolute;
      top: 2px;
      left: 8px;
      display: table;
      width: 9px;
      height: 16px;
      border: 3px solid #fff;
      border-top-width: 0;
      border-left-width: 0;
      transform: rotate(45deg);
      z-index:1;
    }
    &:checked + label:after{
      background-color: #2196f3;
      border-color: #2196f3;
    }
    &:disabled + label:after {
      border-color: #bbbbbb;
    }
    &:disabled:checked + label:after {
      background-color: #bbbbbb;
      border-color: transparent;
    }
  }
}
