// Layout

.u-cf {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.u-float {
  &-left  { float: left !important; }
  &-right { float: right !important; }
}

.u-align{
  &-top    { vertical-align: top !important; }
  &-bottom { vertical-align: bottom !important; }
  &-middle { vertical-align: middle !important; }
}
