.box-solution {
  background: url(../img/bg-solution2.jpg) center center no-repeat;
  background-size: cover;
  min-height: rem(360);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(40) rem(20);
  text-align: center;
  justify-content: space-between;

  @include media('>=sm') {
    width: 50%;
    min-height: rem(400);

    &:not(.box-solution--mozzo) {
      margin-top: rem(-80);
    }
  }

  @include media('>=md') {
    min-height: rem(480);
    padding: rem(60) rem(40);
  }

  &__logo {
    max-width: rem(240);
    display: block;
    height: auto;

    @include media('>=sm') {
      max-width: 100%;
    }
  }

  &__title {
    font-size: rem(18);
    text-transform: uppercase;
    color: $color-bf;
    font-weight: normal;

    @include media('>=md') {
      font-size: rem(22);
    }

    .box-solution--mozzo & {
      color: $color-mz;
    }
  }

  &__desc {
    @include media('>=md') {
      font-size: rem(20);
    }
  }

  &--mozzo {
    background-image: url(../img/bg-solution1.jpg);
  }
}
