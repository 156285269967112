@font-face {
  font-family: "icons";
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('eot'),
       url('../fonts/icons.woff') format('woff'),
       url('../fonts/icons.ttf') format('truetype'),
       url('../fonts/icons.svg#icons') format('svg');
}

%icon {
  position: relative;
  display:inline-block;
  vertical-align: middle;
  font-family:"icons";
  font-style:normal;
  font-weight:normal;
  line-height:1;
  speak:none;
  text-decoration:inherit;
  text-transform:none;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == apps {
    $char: "\E001";
  }
  @if $filename == arrow-down {
    $char: "\E002";
  }
  @if $filename == arrow-up {
    $char: "\E003";
  }
  @if $filename == companyd {
    $char: "\E004";
  }
  @if $filename == marker {
    $char: "\E005";
  }
  @if $filename == phone {
    $char: "\E006";
  }
  @if $filename == presse {
    $char: "\E007";
  }
  @if $filename == scroll-down {
    $char: "\E008";
  }
  @if $filename == send {
    $char: "\E009";
  }
  @if $filename == twitter {
    $char: "\E00A";
  }

  @return $char;
}

@mixin icon($filename) {
  content: icon-char($filename);
}

[class*="icon-"] {
  @extend %icon;
}


.icon-apps{
  &::before {
    @include icon(apps);
  }
}
%icon-apps {
  @include icon(apps);
}

.icon-arrow-down{
  &::before {
    @include icon(arrow-down);
  }
}
%icon-arrow-down {
  @include icon(arrow-down);
}

.icon-arrow-up{
  &::before {
    @include icon(arrow-up);
  }
}
%icon-arrow-up {
  @include icon(arrow-up);
}

.icon-companyd{
  &::before {
    @include icon(companyd);
  }
}
%icon-companyd {
  @include icon(companyd);
}

.icon-marker{
  &::before {
    @include icon(marker);
  }
}
%icon-marker {
  @include icon(marker);
}

.icon-phone{
  &::before {
    @include icon(phone);
  }
}
%icon-phone {
  @include icon(phone);
}

.icon-presse{
  &::before {
    @include icon(presse);
  }
}
%icon-presse {
  @include icon(presse);
}

.icon-scroll-down{
  &::before {
    @include icon(scroll-down);
  }
}
%icon-scroll-down {
  @include icon(scroll-down);
}

.icon-send{
  &::before {
    @include icon(send);
  }
}
%icon-send {
  @include icon(send);
}

.icon-twitter{
  &::before {
    @include icon(twitter);
  }
}
%icon-twitter {
  @include icon(twitter);
}
