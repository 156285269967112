.btn {
  min-width: rem(140);
  display: inline-block;
  color: #fff;
  font-size: rem(15);
  text-transform: uppercase;
  background-color: $color-bf;
  text-align: center;
  padding: 0 rem(20);
  height: rem(40);
  line-height: rem(40);
  position: relative;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    transform: scaleX(0);
    transform-origin: left;
    background-color: rgba(#fff, 0.2);
    transition: $transition-base;
  }

  &:hover::after {
    transform: none;
  }

  @include media('>=md') {
    font-size: rem(20);
    min-width: rem(185);
    height: rem(55);
    line-height: rem(55);
  }

  &--close-banner-msg{
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }

  &--mozzo {
    background-color: $color-mz;
  }

  &--submit {
    background-color: #fff;
    color: $color1;

    &::after{
      background-color: rgba($color1, 0.2);
    }
  }
}
