.team {
  @include media('>=sm') {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    background: url(../img/bg-team.jpg) center center no-repeat;
    background-size: cover;
    margin: 0 rem(-20) rem(20);
    padding: rem(60) rem(40);
    font-size: rem(36);
    text-transform: uppercase;

    @include media('>=sm') {
      margin: 0;
      float: left;
      width: rem(320);
    }

    @include media('>=md') {
      width: rem(410);
    }
  }

  &__content {
    @include media('>=sm') {
      flex: 1;
    }
  }

  &__desc {
    border: 6px solid #fff;
    background-color: $color1;
    padding: rem(15);

    @include media('>=sm') {
      margin: rem(60) 0 0 rem(-40);
    }

    @include media('>=md') {
      margin-left: rem(-100);
      font-size: rem(22);
    }
  }
}
