.box-number {
  margin-bottom: rem(30);

  @include media('>=sm') {
    margin-bottom: 0;
  }

  &:not(.box-number--mozzo) {
    @include media('>=md') {
      margin-top: rem(40);
    }
  }

  &__title {
    width: rem(170);
    height: rem(240);
    font-size: rem(24);
    color: $color-bf;
    margin: 0;
    background-color: #fff;
    padding: rem(15) 0 0 rem(20);

    @include media('>=md') {
      font-size: rem(32);
      width: rem(230);
      height: rem(320);
      padding-left: rem(30);
    }

    .box-number--mozzo & {
      color: #fff;
      background-color: #313657;
    }
  }

  &__wrapper {
    background-color: $color-bf;
    margin: rem(-185) 0 0 rem(30);
    padding: rem(30);

    @include media('>=md') {
      padding: rem(40);
      margin-top: rem(-250);
    }

    .box-number--mozzo & {
      background-color: $color-mz;
    }
  }

  &__desc {
    margin: 0;
    font-size: rem(18);
    line-height: 1.3;

    @include media('>=md') {
      font-size: rem(24);
    }
  }

  &__item {
    border: 6px solid #fff;
    margin-bottom: rem(20);
    padding: rem(10) rem(20) rem(10) 0;
    display: flex;
    align-items: center;

    @include media('>=md') {
      padding: rem(15) rem(20) rem(15) 0;
    }
  }

  &__icon {
    width: rem(100);
    font-size: rem(95);
    text-align: center;
    line-height: 1;

    @include media('>=md') {
      font-size: rem(120);
      width: rem(130);
    }

    i {
      display: block;
    }
  }

  &__img {
    width: rem(100);
    font-size: rem(95);
    text-align: center;
    line-height: 1;

    img {
      width: rem(130);
    }

    @include media('>=md') {
      font-size: rem(120);
      width: rem(130);
    }
  }

  &__content {
    flex: 1;
    font-size: rem(20);
    text-align: center;
    line-height: 1.3;

    @include media('>=md') {
      font-size: rem(32);
    }

    strong {
      display: block;
      font-size: rem(73);
      line-height: 0.8;

      @include media('>=md') {
        font-size: rem(97);
      }
    }
  }
}
